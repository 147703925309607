<template>
  <div class="projectIndex">
    <div class="banner-content">
      <div class="mask mask-left"></div>
      <el-carousel>
        <el-carousel-item v-for="item in bannerList" :key="item">
          <img class="banner-item" :src="item" alt="" />
        </el-carousel-item>
      </el-carousel>
      <div class="mask mask-right"></div>
    </div>
    <!-- <el-radio-group class="tab" v-model="tab" style="margin-bottom: 30px;">
      <el-radio-button label="isHot">热门项目</el-radio-button>
      <el-radio-button label="isRecommend">推荐项目</el-radio-button>
    </el-radio-group> -->


    <div class="list" v-for="(item, key) in project" :key="key">
      <p class="title">
        <span class="hot">{{ item.title }}</span>
        <span class="more" @click="toMore(key)">查看更多<i class="el-icon-arrow-right"></i></span>
      </p>
      
      <div class="hot-list" v-if="key == 'isHot'">
        <template v-if="item.list.length">
          <div class="item" v-for="(items, indexs) in item.list" :key="indexs" @click="toDetail(items)">
            <img :src="items.cover" class="cover" alt="">
            <div class="content" :style="{background: hotBackgroundColors[indexs]}">
              <div class="enterpriseName">
                {{items.enterpriseName}}
              </div>
              <div class="industryName" v-for="name in (items.industryName || '').split(',').map(i => i)" :key="name">
                {{name}}
              </div>
              <div class="businessIncomeName">
                <div class="nameT">营收</div>：{{items.businessIncomeName || '--'}}
              </div>
              <div class="netProfitName">
                <div class="nameT">净利润</div>：{{items.netProfitName || '--'}}
              </div>
              <div class="address">
                <div class="nameT">地址</div>：{{items.provinceName || items.cityName ? `${items.provinceName && items.cityName ? `${items.provinceName}/${items.cityName}` : items.provinceName || items.cityName}` : '--'}}
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="empty">
            <el-empty :image-size="150"></el-empty>
          </div>
        </template>
      </div>
      <div class="recommend-list" v-else>
        <contentItem  class="projectItem" :data="items"  v-for="(items, indexs) in item.list" :key="indexs"></contentItem>
      </div>
      <!-- <template v-if="activteData.list.length">
        <div class="content">
            <contentItem  @click.native="$router.push({path: '/clientProjectDetail', query: { id: item.id, enterpriseName: item.enterpriseName }})" :data="item"  v-for="(item, index) in activteData.list" :key="index"></contentItem>
        </div>
      </template>
      <template v-else>
        <el-empty :image-size="200"></el-empty>
      </template> -->
    </div>
    <div class="list">
      <p class="title">
        <span class="hot">合作机构</span>
      </p>
      <img style="width: 100%;" src="@/assets/homeindex/cooperate.png" alt="">
    </div>
        
    <div class="footer">
      <p>创投小圈子——一级市场创投人的聚集中心<br/>Copyright © 2024 创投小圈子（www.fundrelation.com） 版权所有 | <a href="https://beian.miit.gov.cn" class="icp" target="_blank">ICP备案/许可证号:沪ICP备2024063314号-1</a>
        <!-- <br />海投联鼎乾财务咨询合伙企业（有限合伙）fundrelation.com版权所有 -->
      </p>
      <div class="qrcode">
        <p>联系我们</p>
        <el-popover
          placement="top"
          trigger="hover">
          <template #reference>
            <img src="@/assets/qrcode.png" alt="">
          </template>
          <img src="@/assets/qrcode.png" style="width: 300px;" alt="">
        </el-popover>
      </div>
    </div>
    <!-- <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="item in bannerList" :key="item">
                    <img :src="item" alt="">
                </div>
            </div>
            <div class="swiper-pagination"></div>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
            <div class="swiper-scrollbar"></div>
        </div> -->
  </div>
</template>
<script>
// import Swiper from 'swiper';
// import "swiper/swiper.min.css"
import contentItem from '@/components/contentItem/index.vue'
export default {
  name: 'index',
  data() {
    return {
      tab: 'isHot',
      hotBackgroundColors: [
        'linear-gradient(0deg, #2da663 0%, #2da663 30%, #5bc78b00 100%)',
        'linear-gradient(0deg, #516bf6 0%, #516bf6 30%, #5bc78b00 100%)',
        'linear-gradient(0deg, #ad8a44 0%, #ad8a44 30%, #ad8a4400 100%)',
        'linear-gradient(0deg, #00aaff 0%, #00aaff 30%, #5bc78b00 100%)',
        'linear-gradient(0deg, #3d87ff 0%, #3d87ff 30%, #3d87ff00 100%)'
      ],
      bannerList: [
					'https://toulian.oss-cn-shanghai.aliyuncs.com/image/2024/08/13/1723558707029/scf2kQ0RBM172cbf061f114c32ec32b91586060664f0.png',
					'https://toulian.oss-cn-shanghai.aliyuncs.com/image/2024/08/13/1723558869995/YkIYVJMiKH6M3be9ce4142326f86f44f6d118da85594.png',
					'https://toulian.oss-cn-shanghai.aliyuncs.com/image/2024/09/02/1725289371185/4X67jFzL90Cd2d526c02fbdae3180deeb389f230c62b.png',
					'https://toulian.oss-cn-shanghai.aliyuncs.com/image/2024/08/12/1723473836082/q38bRR0mSLgw3f03618abef6e73defc885a0a670d228.png',
					'https://toulian.oss-cn-shanghai.aliyuncs.com/image/2024/09/02/1725289392545/qMUDna6xyFIx7c2f65f6d00255a7e1a25c7b2a394411.png',
					'https://toulian.oss-cn-shanghai.aliyuncs.com/image/2024/08/13/1723558884223/2YGfExCodXyM854ba4a418e04552f0309b5d43e2102a.png',
					'https://toulian.oss-cn-shanghai.aliyuncs.com/image/2024/09/02/1725289419119/QPyVpOfQ7Yyi4b9e065db50992bc97aafa8c9f786d39.png'
      ],
      project:  {
        isHot: {
            title: '热门项目',
            list: []
        },
        isRecommend: {
            title: '推荐项目',
            list: []
        }
      }
    };
  },
  computed: {
    activteData() {
      return this.project[this.tab]
    }
  },
  components: {
    contentItem
  },
  mounted() {
    // new Swiper ('.swiper-container', {
    //     loop: true,
    //     // 如果需要前进后退按钮
    //     nextButton: '.swiper-button-next',
    //     prevButton: '.swiper-button-prev',
    //     // 如果需要滚动条
    //     scrollbar: '.swiper-scrollbar',
    //     navigation: {
    //     nextEl: ".swiper-button-next",
    //     prevEl: ".swiper-button-prev",
    // },

    // pagination: {
    //     el: ".swiper-pagination",
    // },
    // })
    this.getList('isHot');
    this.getList('isRecommend');
  },
  methods: {
    async toDetail(data) {
      // await this.$store.state.clientLoginRef.init()
      const { id, enterpriseName, cover, industryName, businessIncomeName, netProfitName, provinceName, provinceCode, cityName, cityCode } = data 
      this.$router.push({
      path: '/clientProjectDetail',
          query: {
              id,
              enterpriseName,
              cover,
              industryName,
              businessIncomeName,
              netProfitName,
              provinceName,
              provinceCode,
              cityName,
              cityCode
          }
      })
    },
    async toMore(key) {
      // await this.$store.state.clientLoginRef.init()
      this.$router.push({
        path: '/clientProjectList',
        query: {
          [key]: 1
        }
      })
    },
    getList(key) {
      this.$http
        .Post(this.$api.hotList, {
          pageNum: 1,
          pageSize: key == 'isHot' ? 5 : 10,
          projectStatus: 2,
          [key]: 1
        })
        .then(({data: { records = [] } = {} }) => {
            this.project[key].list = records
        });
    },
  },
};
</script>
<style lang="less" scoped>
.banner-content{
  width: 100%;
  display: flex;
  .mask{
    flex: 1;
    position: relative;
    &::before{
      content: '';
      position: absolute;
      width: 130%;
      height: 100%;
    }
    &-left{
      &::before{
        left: 0;
        background: repeating-linear-gradient(to right,#010217 0%,#010217 80%,#010217 80%,rgba(0,0,0,0) 100%);
        z-index: 5;
      }
    }
    &-right{
      &::before{
        right: 0;
        background: repeating-linear-gradient(to left,#010217 0%,#010217 80%,#010217 80%,rgba(0,0,0,0) 100%);
        z-index: 5;
      }
    }
  }
  .banner-item {
    display: block;
    margin: 0 auto;
    width: 1125px;
    height: 600px;
    // aspect-ratio: 16 / 9;
  }
}
/deep/ .el-carousel {
  width: 1125px;
  margin: 0 auto;
  .el-carousel__container {
    width: 100%;
    height: 600px;
    // aspect-ratio: 16 / 9;
    // height: auto;
  }
  .el-carousel__indicators--horizontal{
    display: flex;
    border-radius: 2px;
    overflow: hidden;
    bottom: 19px;
  }
  .el-carousel__arrow--left, .el-carousel__arrow--right{
    display: none;
  }
  .el-carousel__indicator--horizontal{
    padding: 0;
    .el-carousel__button{
      height: 4px;
      background-color: #fff;
      opacity: 1;
    }
    &.is-active{
      .el-carousel__button{
        background-color: #1C68FF;
      }
    }
  }
}
.hot-list{
  max-height: 227px;
  display: flex;
  .empty{
    flex: 1;
    /deep/.el-empty{
      padding: 0;
    }
  }
  .item{
    width: 204px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    height: 227px;
    .cover{
      width: 100%;
      height: 100px;
      object-fit: cover;
    }
    .content{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding-top: 100px;
      box-sizing: border-box;
			.enterpriseName{
        color: #ffffff;
        font-size: 16px;
        margin: 0 10px 5px 12px;
        white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.businessIncomeName, .netProfitName, .address{
        color: rgba(255,255,255,.4);
        font-size: 13px;
        margin: 0 12px 6px 12px;
        display: flex;
				align-items: center;
			}
			.industryName{
				border: 1px solid #fff;
				padding: 2px 5px;
				color: #ffffff;
				font-size: 10px;
				display: inline-block;
        border-radius: 3px;
				margin-left: 12px;
				margin-bottom: 10px;
        &+.industryName{
          margin-left: 5px;
        }
			}
			.image-container{
				width: 141px;
				height: 232px;
				.cover{
          width: 100%;
          height: 100%;
				}
			}
			.nameT{
				width: 45px;
				flex-shrink: 0; 
				// display: flex;
				// justify-content: space-between;
				text-align-last: justify;
			}
    }
    &+.item{
      margin-left: 26px;
    }
  }
}
.recommend-list{
  display: flex;
  flex-wrap: wrap;
  gap: 24px 40px;
  .projectItem{
    width: 540px;
  }
}
.projectIndex{
  // width: 1125px;
  // margin: 0 auto;
  background-color: #fff;
  overflow: hidden;
  .tab{
    margin: 20px auto !important;
  }
}
.list {
  width: 1125px;
  border-radius: 5px;
  margin: 36px auto;
  box-sizing: border-box;
  .title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    // border-bottom: 1px solid #ebeef5;
    font-size: 20px;
    align-items: center;
    .hot{
        font-weight: bold;
    }
    .more{
        color: #929292;
        cursor: pointer;
        font-size: 16px;
    }
  }
  // .content{
  //   width: 100%;
  //   display: flex;
  //   flex-wrap: wrap;
  //   gap: 24px;
  //   padding-top: 20px;
  // }
}

.footer{
    width: 100%;
    height: 200px;
    background-color: #2E3742;
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    p{
      width: 900px;
      color: #ffffff;
      font-size: 14px;
      line-height: 24px;
    }
    .icp{
      text-decoration: none;
      color: #fff;
      line-height: 23px;
      &:hover{
        color: #929292;
      }
    }
    .qrcode{
      width: 100PX;
      p,img{
        width: 100%;
      }
      img{
        cursor: pointer;
      }
    }
  }
</style>