<template>
    <div class="project-item">
        <el-image :src="data.cover" class="cover" fit="fill">
            <div slot="error" class="error">
                <i class="el-icon-picture-outline"></i>
            </div>
        </el-image>
        <div class="bottom">
            <p class="name">{{ data.enterpriseName ||data.projectName }}</p>
            <p class="item">
                <el-tag size="mini" v-for="item in (data.industryName || '').split(',').filter(i => i)" :key="item" style="margin-right: 5px;">{{item}}</el-tag>
                <!-- <el-tooltip :open-delay="600" effect="dark" :content="data.industryName || '--'" placement="top">
                    <span class="rd">{{data.industryName || '--'}}</span>
                </el-tooltip> -->
            </p>
            <div class="business">
                <p>
                    <img class="icon" src="@/assets/dollar.png" alt="">
                    营收：{{ data.businessIncomeName || '--' }}
                </p>
                <i>|</i>
                <p>
                    <img class="icon" src="@/assets/dollar.png" alt="">
                    净利润：{{ data.netProfitName || '--' }}
                </p>
            </div>
            <div class="address">
                {{ data | address }}
                <el-button class="toDetail" size="small" type="primary" @click="toDetail()" >查看详情</el-button>
            </div>
            <!-- <p class="item">
                <span class="rt">地址</span>
                <el-tooltip :open-delay="600" effect="dark" :content="data | address" placement="top">
                    <span class="rd">{{data | address}}</span>
                </el-tooltip>
            </p>
            <p class="item">
                <span class="rt">最近财年营业收入</span>
                <el-tooltip :open-delay="600" effect="dark" :content="data.businessIncomeName || '--'" placement="top">
                    <span class="rd">{{data.businessIncomeName || '--'}}</span>
                </el-tooltip>
            </p>
            <p class="item">
                <span class="rt">最近财年净利润</span>
                <el-tooltip :open-delay="600" effect="dark" :content="data.netProfitName || '--'" placement="top">
                    <span class="rd">{{data.netProfitName || '--'}}</span>
                </el-tooltip>
            </p> -->
        </div>
        <div class="footer">

        </div>
    </div>
</template>
<script>
export default{
    props: {
        data: {
            type: Object,
            default: () => ({})
        }
    },
    filters: {
        address(data) {
            return `${data.provinceName || ''}${data.provinceName && data.cityName ? '/' : ''}${data.cityName || ''}`
        }
    },
    methods:  {
        async toDetail() {
            // await this.$store.state.clientLoginRef.init()
            this.$router.push({
            path: '/clientProjectDetail',
                query: { id: this.data.id, enterpriseName: this.data.enterpriseName}
            })
        }
    }
}

</script>
<style lang="less" scoped>
.project-item{
    width: 540px;
    height: 130px;
    display: flex;
    .cover{
        width: 130px;
        height: 130px;
        border-radius: 4px;
        overflow: hidden;
        margin-right: 16px;
        /deep/ .error{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30px;
            background: #f5f7fa;
            color: #909399;
        }
    }
    .bottom{
        flex: 1;
        width: 0;
        .name{
            font-size: 20px;
            font-weight: 700;
            color: #2e3742;
            font-size: 16px;
            margin-bottom: 6px;
        }
        .item{
            display: flex;
            margin: 10px 0 0;
            width: 100%;
            /deep/.el-tag{
                border: 1.37px solid rgba(28, 104, 255, 0.4);
                background: rgba(28, 104, 255, 0.05);
                color: rgba(28, 104, 255, 1);
            }
            .rt{
                font-size: 14px;
                width: 140px;
            }
            .rd{
                flex: 1;
                width: 0;
                font-size: 14px;
                color: #999;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 0;
            }
        }
        .business{
            display: flex;
            color: #2e3742;
            font-size: 14px;
            margin-bottom: 10px;
            height: 36px;
            p{
                display: flex;
                align-items: center;
                .icon{
                    width: 16px;
                    height: 16px;
                    margin-right: 8px;
                }
            }
            i{
                color: #CFD4DB;
                margin: 0 17px;
            }
        }
        .address{
            color: #2e3742;
            font-size: 14px;
            display: flex;
            justify-content: space-between;
            align-items: end;
            .toDetail{
                background-color: #1C68FF;
            }
        }
    }
}
</style>